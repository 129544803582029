<template>
    <Dialog v-model:visible="display" :modal="true" :style="{ width: '60vw' }" @hide="cancelar()" @show="preencher()" :contentStyle="{ overflow: 'visible' }">
        <template #header>
            <h5>{{ titulo }}</h5>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-4">
                <label>Código</label>
                <InputText type="text" v-model="regraTemp.codigo" />
                <small class="p-error" v-if="v$.regraTemp.codigo.$error">O código é obrigatório</small>
            </div>
            <div class="field col-8">
                <label>Descrição</label>
                <InputText type="text" v-model="regraTemp.descricao" />
                <small class="p-error" v-if="v$.regraTemp.descricao.$error">A descrição é obrigatória</small>
            </div>
            <div class="field col-2" v-if="$temAcessoView('CTRLACS-RA-04')">
                <label>Nível</label>
                <InputText type="text" v-model="regraTemp.nivel" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            <Button :label="acao === 'I' ? 'Inserir' : 'Atualizar'" icon="pi pi-check" @click="confirmarSalvar()" :disabled="naoPermiteSalvar" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        mostrar: {
            type: Boolean,
            default: false,
        },

        titulo: {
            type: String,
        },

        regra: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        reset: {
            type: Boolean,
            default: false,
        },

        acao: {
            type: String,
            default: 'I',
        },
    },

    emits: ['cancelar', 'salvar', 'resetado'],

    data() {
        return {
            display: false,
            regraTemp: {
                codigo: null,
                descricao: null,
                nivel: 0,
            },
        };
    },

    validations() {
        return {
            regraTemp: {
                codigo: { required },
                descricao: { required },
            },
        };
    },

    methods: {
        cancelar() {
            this.resetar();
            this.$emit('cancelar');
        },

        preencher() {
            if (this.acao === 'A') {
                this.regraTemp = {
                    codigo: this.regra.codRegraAcesso,
                    descricao: this.regra.descricaoRegraAcesso,
                    nivel: this.regra.nivel,
                };
            }
            this.v$.$touch();
        },

        resetar() {
            this.regraTemp = {
                codigo: null,
                descricao: null,
                nivel: 0,
            };
            this.$nextTick(() => {
                this.v$.$reset();
            });
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: 'Tem certeza que deseja prosseguir?',
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$emit('salvar', this.regraTemp);
        },
    },

    watch: {
        mostrar() {
            this.display = this.mostrar;
        },

        reset() {
            if (this.reset) {
                this.resetar();
                this.$emit('resetado');
            }
        },
    },

    computed: {
        naoPermiteSalvar() {
            return this.v$.$invalid;
        },
    },
};
</script>
