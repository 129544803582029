<template>
    <font-awesome-icon icon="edit" @click="abrir()" size="lg" class="icon-button p-text-primary" title="Atualizar" />
    <regra-form
        acao="A"
        :mostrar="mostrar"
        titulo="Atualizar Regra de Acesso"
        :regra="regraBase"
        @cancelar="fechar()"
        @salvar="atualizar($event)"
        :erros="erros"
    ></regra-form>
</template>

<script>
import RegraForm from './RegraForm';
import RegrasServices from './services';

export default {
    components: {
        RegraForm,
    },

    props: {
        regra: {
            type: Object,
        },
    },

    emits: ['atualizar'],

    data() {
        return {
            mostrar: false,
            regraBase: null,
            erros: [],
            atualizado: false,
        };
    },

    methods: {
        abrir() {
            this.obterRegra();
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            if (this.atualizado) {
                this.$emit('atualizar');
                this.atualizado = false;
            }
            this.erros = [];
        },

        obterRegra() {
            this.$store.dispatch('addRequest');
            RegrasServices.obterPorId(this.regra.regraAcessoId).then(r => {
                if (r) {
                    if (r.success) {
                        this.regraBase = r.data;
                        this.mostrar = true;
                    } else {
                        this.regraBase = null;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(regra) {
            this.$store.dispatch('addRequest');
            let regraAtualizada = {
                regraAcessoId: this.regraBase.regraAcessoId,
                codigo: regra.codigo,
                descricao: regra.descricao,
                area: regra.area,
                subArea: regra.subArea,
                associar: regra.associar,
            };
            if (this.$temAcessoView('CTRLACS-RA-04')) {
                regraAtualizada.nivel = regra.nivel;
            }
            RegrasServices.atualizar(regraAtualizada).then(r => {
                if (r) {
                    if (r.success) {
                        this.regraBase = r.data;
                        this.atualizado = true;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Atualização de Regra',
                            detail: 'Regra de acesso atualizada com sucesso',
                            life: 3000,
                            group: 'dialog',
                        });
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
