import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}/administracao`;

export default {
    obterTodas() {
        return axiosJwt.get(`${api}/controleacesso/regrasacesso`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/controleacesso/regrasacesso/${id}`);
    },

    inserir(regra) {
        return axiosJwt.post(`${api}/controleacesso/regrasacesso`, regra);
    },

    atualizar(regra) {
        return axiosJwt.put(`${api}/controleacesso/regrasacesso`, regra);
    },
};
