<template>
    <Button label="Nova Regra" icon="pi pi-plus" iconPos="left" @click="abrir()" class="p-button" />
    <regra-form
        :mostrar="mostrar"
        :reset="resetForm"
        titulo="Inserir Regra de Acesso"
        @cancelar="fechar()"
        @salvar="inserir($event)"
        :erros="erros"
        @resetado="resetForm = false"
    ></regra-form>
</template>

<script>
import RegraForm from './RegraForm';
import RegrasServices from './services';

export default {
    components: {
        RegraForm,
    },

    emits: ['atualizar'],

    data() {
        return {
            mostrar: false,
            erros: [],
            inserido: false,
            resetForm: false,
        };
    },

    methods: {
        abrir() {
            this.mostrar = true;
        },

        fechar() {
            if (this.mostrar) {
                this.mostrar = false;
            }
            if (this.inserido) {
                this.$emit('atualizar');
                this.inserido = false;
            }
            this.erros = [];
        },

        reset() {
            this.resetForm = true;
            this.erros = [];
        },

        inserir(regra) {
            this.$store.dispatch('addRequest');
            let regraInserida = {
                codigo: regra.codigo,
                descricao: regra.descricao,
                area: regra.area,
                subArea: regra.subArea,
                associar: regra.associar,
            };
            if (this.$temAcessoView('CTRLACS-RA-04')) {
                regraInserida.nivel = regra.nivel;
            }
            RegrasServices.inserir(regraInserida).then(r => {
                if (r) {
                    if (r.success) {
                        this.inserido = true;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Inserção de Regra',
                            detail: 'Regra de acesso inserida com sucesso',
                            life: 3000,
                            group: 'dialog',
                        });
                        this.reset();
                    } else {
                        this.erros = r.errors;
                    }
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
